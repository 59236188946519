import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import {useEffect} from "react";
import {getEnv, isProduction} from "~/helpers/environment";
import {useUser} from "@clerk/clerk-react";

if (isProduction) {
  if(process.env.NEXT_PUBLIC_DATADOG_TOKEN) {
    // TODO: create logger and use that instead of console
    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
      service: 'frontend',
      env: process.env.NEXT_PUBLIC_GG_NODE_ENV,
      version: process.env.NEXT_PUBLIC_APP_VERSION,
      beforeSend: (log) => {
        // remove email from view url
        log.view.url = log.view.url.replace(/authToken=[^&]*/, "authToken=REDACTED")
      },
    })
  }

  if(process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN) {
    datadogRum.init({
      applicationId: '9a55191f-1901-4a2c-b1dc-5ccbcbfe7c67',
      clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN,
      service: 'frontend',
      version: process.env.NEXT_PUBLIC_APP_VERSION,
      env: getEnv(),
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0, // Disable session recording
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording()
  }
}

export const useDatadog = () => {
  const { user } = useUser()

  useEffect(() => {
    if(user) {
      const ddUser = {
        id: user.externalId,
        username: user.publicMetadata.username
      }
      datadogLogs.setUser(ddUser)
      datadogRum.setUser(ddUser)
    }
  }, [user]);
}
