'use client'

import axios from 'axios'
import {buildStorage, setupCache} from 'axios-cache-interceptor'
import localforage from 'localforage'
import { store as reduxStore } from '~/store'
import { storage } from '~/helpers'
import { addAnnouncement, SERVER_ERROR } from '~/utils/announcements'
import {getApiURL} from '~/helpers/environment'

const defaultOptions = {
  baseURL: getApiURL()
}

const store = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE
  ],
  name: 'api-cache'
})

const defaultCacheOptions = {
   cleanupInterval: 1000,
   storage: buildStorage({
      async find(key) {
          const value = await store.getItem(key);

          if (!value) {
              return;
          }

          return JSON.parse(value);
      },

      async set(key, value) {
          await store.setItem(key, JSON.stringify(value));
      },

      async remove(key) {
          await store.removeItem(key);
      }
  })
}


const instance = axios.create({
    ...defaultOptions,
    timeout: 10000
})
export const api = setupCache(instance, defaultCacheOptions)

api.interceptors.request.use(
    async config => {

      let reduxToken
      try {
        reduxToken = reduxStore.getState().authenticationReducer.token
      } catch {
        console.warn('No token.')
      }

      const token = storage.getItem('token') || reduxToken

      if (!token) {
        const isAuthURL = !config.url.replace(config.baseURL, '').indexOf('auth/')

        if (!isAuthURL) {
          throw new Error('Missing token.')
        }
      }

      const isLoginURL = !config.url.replace(config.baseURL, '').indexOf('auth/login')
      if (token && !config.headers.Authorization && !isLoginURL) {
        config.headers.Authorization = `Token ${token}`
      }

      return config
    },
    error => {
      // Do something with request error
      return Promise.reject(error)
    }
)

api.interceptors.response.use(
    res => {
      return res
    },
    error => {
      if (navigator.onLine) {
        try {
          const { config, response, code } = error

          const path = config.url.replace(config.baseURL, '').split('?')[0]

          if (config.url !== 'users/@me') {
            if ((code === 'ECONNABORTED' && (path && !path.startsWith('ed/'))) || (response?.status >= 500)) {
              addAnnouncement(SERVER_ERROR)
            }
          }
        } catch {
          //noop
        }
      }

      return Promise.reject(error)
    }
)

if (typeof window !== 'undefined') {
  window.axios = axios
  window.api = api
}
