// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import * as Sentry from '@sentry/browser'
import * as SentryIntegrations from '@sentry/integrations'
import {isDevelopment, isProduction} from "~/helpers/environment";
// import SentryTestkit from 'sentry-testkit'

module.exports = (release = process.env.SENTRY_RELEASE) => {
  const sentryOptions = {
    enabled: isProduction,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    environment: process.env.NEXT_PUBLIC_GG_NODE_ENV || 'test'
  }

  // Only enable on test and prod
  if (isDevelopment) {
    // const { sentryTransport } = SentryTestkit()

    // Don't actually send the errors to Sentry
    // sentryOptions.transport = sentryTransport

    // Instead, dump the errors to the console
    sentryOptions.integrations = [
      new SentryIntegrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: true
      })
    ]
  }

  Sentry.init(sentryOptions)

  return {
    Sentry,
    captureException: (err, ctx, user = {}) => {
      Sentry.configureScope(scope => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message])
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode)
        }

        if (typeof window !== 'undefined') {
          scope.setUser({
            email: user?.email,
            deviceFingerprint: localStorage.getItem('fingerprint')
          })
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode)
          }

          if (typeof window !== 'undefined') {
            scope.setTag('ssr', false)
            scope.setExtra('query', query)
            scope.setExtra('pathname', pathname)
          } else {
            scope.setTag('ssr', true)
            scope.setExtra('url', req.url)
            scope.setExtra('method', req.method)
            scope.setExtra('headers', req.headers)
            scope.setExtra('params', req.params)
            scope.setExtra('query', req.query)
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach(key =>
              scope.setExtra(key, errorInfo[key])
            )
          }
        }
      })

      if (isDevelopment) {
        console.log('===============SENTRY===============')
        console.log('Sentry', Sentry)
        console.log('err', err)
        console.log('=============SENTRY END===============')
        return
      }

      return Sentry.captureException(err)
    }
  }
}
