import React from 'react'
import { ScreenContext } from '~/contexts'
import { throttle, isElementFullyInViewport } from '~/helpers'
import { FRAGMENTS_BREAKPOINT } from '~/config/settings'
import deepEqual from 'fast-deep-equal'
import {isDevelopment} from "~/helpers/environment";

class ScreenProvider extends React.Component {
  state = {
    screen: {
      // width: (typeof window !== 'undefined' ? window.innerWidth : 1),
      width: 1,
      // height: (typeof window !== 'undefined' ? window.innerWidth : 1),
      height: 1,
      orientation: (typeof window !== 'undefined' ? (matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape') : 'portrait'),
      standalone: (typeof window !== 'undefined' ? (matchMedia('(display-mode: standalone)').matches) : false),
      fullscreen: false,
      shouldDisplay: {
        fragments: true,
        shards: false,
        rotateDevice: false
      },
      setInputMask: (inputMask) => {
        this.setState({
          inputMask
        })
      }
    }
  }

  maskStyle = {
    position: 'fixed',
    bottom: '10px',
    left: '50%',
    // width: '300px',
    // minWidth: '300px',
    // maxWidth: '300px',
    // marginLeft: '-150px',
    zIndex: 1000
  }

  onScroll = () => {
    const { screen } = this.state

    if (screen.activeElement && isElementFullyInViewport(screen.activeElement)) {
      Object
        .keys(this.maskStyle)
        .map(key => {
          screen.activeElement.style[key] = null
        })

      screen.activeElement.blur()
    }
  }

  onScreenResize = throttle(() => {
    clearTimeout(this.fullscreenTimeout)
    const fullscreen = !!document.fullscreenElement

    if (fullscreen === this.state.screen.fullscreen || !0) {
      const screen = {
        ...this.state.screen,
        width: window.innerWidth,
        height: window.innerHeight,
        orientation: matchMedia('(orientation: portrait)').matches
          ? 'portrait'
          : 'landscape',
        standalone: matchMedia('(display-mode: standalone)').matches,
        fullscreen,
        shouldDisplay: {
          fragments: true,
          shards: false,
          rotateDevice: false,
          inputMask: false
        }
      }

      try {
        const tag = document.activeElement.tagName.toLowerCase()
        screen.shouldDisplay.inputMask = ['input', 'textarea']
          .includes(tag) && (screen.height !== this.state.screen.height)
      } catch (error) {
        screen.shouldDisplay.inputMask = false
      }

      if (screen.activeElement) {
        Object
          .keys(this.maskStyle)
          .map(key => {
            screen.activeElement.style[key] = null
          })

        screen.activeElement = null
      }

      window.removeEventListener('scroll', this.onScroll)

      if (screen.width === this.state.screen.width && (screen.height < this.state.screen.height && (this.state.screen.height - screen.height) > 100 && screen.fullscreen === this.state.screen.fullscreen)) {
        // screen.width = this.state.screen.width
        screen.height = this.state.screen.height
        screen.orientation = this.state.screen.orientation

        if (isDevelopment) {
          screen.shouldDisplay.inputMask = true

          setTimeout(() => {
            if (!isElementFullyInViewport(document.activeElement)) {
              screen.activeElement = document.activeElement

              const rect = screen.activeElement.getBoundingClientRect()

              this.maskStyle.width = `${rect.width}px`
              this.maskStyle.marginLeft = `${-(rect.width / 2)}px`

              Object
                .keys(this.maskStyle)
                .map(key => {
                  screen.activeElement.style[key] = this.maskStyle[key]
                })

              window.addEventListener('scroll', this.onScroll)
            }
          }, 500)
        }
      }

      // override input mask
      // screen.shouldDisplay.inputMask = false

      document.documentElement.style
        .setProperty('--vw', `${screen.width}px`)

      document.documentElement.style
        .setProperty('--vh', `${screen.height}px`)

      // Array.from(document.querySelectorAll('html,body'))
      //   .map(el => {
      //     // el.style.width = screen.width + 'px'
      //     el.style.height = screen.height + 'px'
      //   })

      if (screen.width > FRAGMENTS_BREAKPOINT && screen.height > FRAGMENTS_BREAKPOINT) {
        if (screen.orientation === 'landscape') {
          screen.shouldDisplay = {
            fragments: false,
            shards: true,
            rotateDevice: false
          }
        }
      } else if (screen.orientation === 'landscape') {
        screen.shouldDisplay = {
          fragments: true,
          shards: false,
          rotateDevice: true
        }
      }

      if (!deepEqual(screen, this.state.screen)) {
        this.setState({ screen })
      }
    } else {
      this.fullscreenTimeout = setTimeout(() => {
        this.setState({
          screen: {
            ...this.state.screen,
            fullscreen
          }
        }, this.onScreenResize)
      }, 250)
    }
  }, 250, true)

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.onScreenResize()
      window.addEventListener('resize', this.onScreenResize)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onScreenResize)
  }

  render () {
    return (
      <ScreenContext.Provider value={{
        ...this.state.screen,
        inputMask: this.state.inputMask
      }}
      >
        {this.props.children}
      </ScreenContext.Provider>
    )
  }
}

export default ScreenProvider
