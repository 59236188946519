import { getGlobal } from '~/helpers'
import {isProduction} from "~/helpers/environment";

export const FRAGMENTS_BREAKPOINT = 550

export const RESIZER_BASE_URL = `https://cdn.gameglass.gg/images${isProduction ? '' : '-dev'}`

export const S3_BUCKET = isProduction ? 'media' : 'media-test'

export const DISCOVER_BASE_URL = 'discover/guides'

export const LAST_LOCATION = 'last_location'

export const USER_INTERVAL = 60000

export const TEST_EMAILS = [
  'applereview@gameglass.gg',
  'windowstoretester@microsoft.com'
]

export const GLOBAL = getGlobal()

const colorsInfo = {
  primary: {
    displayName: 'Main'
  },
  primaryHighlight: {
    displayName: 'Main (selected)'
  },
  secondary: {
    displayName: 'Secondary'
  },
  secondaryHighlight: {
    displayName: 'Secondary (selected)'
  },
  action: {
    displayName: 'Alternate 1'
  },
  actionHighlight: {
    displayName: 'Alternate 1 (selected)'
  },
  warning: {
    displayName: 'Alternate 2'
  },
  warningHighlight: {
    displayName: 'Alternate 2 (selected)'
  },
  textLight: {
    displayName: 'Light text'
  },
  textLightHighlight: {
    displayName: 'Light text (selected)'
  },
  textDark: {
    displayName: 'Dark text'
  },
  textDarkHighlight: {
    displayName: 'Dark text (selected)'
  }
}

export const COLORS_INFO = Object
  .keys(colorsInfo)
  .reduce((acc, key, order) => {
    acc[key] = {
      ...colorsInfo[key],
      order
    }

    return acc
  }, {})

export const SHARD_WIDTH = 215
export const SHARD_HEIGHT = 241
export const CONDENSED_SHARD_HEIGHT = 103
export const SHARD_GRID_GAP = 14

export const SUPPORT_URL = 'https://support.gameglass.gg'
