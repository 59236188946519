import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, getNestedProp } from '~/helpers'
import { getUser } from '~/store/actions'
import captureError from '~/utils/captureError'
import Announcement from '~/components/common/Announcement'

function ErrorComponent ({
  err,
  statusCode,
  type,
  title,
  subTitle,
  children
}) {
  const dispatch = useDispatch()
  const user = useSelector(state => {
    return get(state, 'authenticationReducer.user')
  })

  let overrideTitle = null
  const defaultErrorText = subTitle || 'An error has occurred.'
  let overrideSubtitle = getNestedProp(err, 'message') || defaultErrorText

  if (err instanceof TypeError || err instanceof ReferenceError) {
    overrideSubtitle = defaultErrorText
  }

  if (statusCode) {
    overrideTitle = statusCode

    switch (overrideTitle) {
    case 404:
      overrideSubtitle = 'Not Found'
      break
    case 500:
      overrideSubtitle = 'Internal Server Error'
      break
    default:
      break
    }
  }

  useEffect(() => {
    (async function sendError () {
      if (!user) {
        await dispatch(await getUser())
      }

      if (err) {
        captureError(err, null, user || {})

        if (typeof window !== 'undefined') {
          console.error(err)
        }
      }
    })()
  }, [])

  return (
    <Announcement
      type={type || 'error'}
      title={overrideTitle || title}
      subTitle={overrideSubtitle || subTitle}
    >
      {children}
    </Announcement>
  )
}

ErrorComponent.getInitialProps = (props) => {
  const { res, err } = props
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404

  return { statusCode }
}

export const getInitialProps = ErrorComponent.getInitialProps

export default ErrorComponent
