export const FORCE_UPDATE = 'FORCE_UPDATE'

export const AUTHENTICATION_START = 'AUTHENTICATION_START'
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS'
export const AUTHENTICATION_FAIL = 'AUTHENTICATION_FAIL'
export const AUTHENTICATION_LOGGED_OUT = 'AUTHENTICATION_LOGGED_OUT'

export const AUTHENTICATION_LOGGED_OUT_FINISHED = 'AUTHENTICATION_LOGGED_OUT_FINISHED'

export const SET_AUTHENTICATION_REDIRECT_PATH = 'SET_AUTHENTICATION_REDIRECT_PATH'

// Star Citizen Specific Actions (Refactor)
export const DISPLAY_EMERGENCY_OVERLAY = 'DISPLAY_EMERGENCY_OVERLAY'
export const HIDE_EMERGENCY_OVERLAY = 'HIDE_EMERGENCY_OVERLAY'
export const TOGGLE_FULL_SCREEN_ON = 'TOGGLE_FULL_SCREEN_ON'
export const TOGGLE_FULL_SCREEN_OFF = 'TOGGLE_FULL_SCREEN_OFF'

export const SCREEN_SET_VALUES = 'SCREEN_SET_VALUES'

export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT'
export const REMOVE_ANNOUNCEMENT = 'REMOVE_ANNOUNCEMENT'

export const SET_SOURCE = 'SET_SOURCE'

export const SET_POWER = 'SET_POWER'
export const SET_SHIELDS = 'SET_SHIELDS'

export const GET_VARIANT_LINKS = 'GET_VARIANT_LINKS'
export const SET_VARIANT_SHARDS = 'SET_VARIANT_SHARDS'
