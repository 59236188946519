import { useCallback } from 'react'
import { openLink } from '~/helpers'

const ExternalLink = ({
  href,
  children
}) => {
  const handleClick = useCallback((e) => {
    e.preventDefault()
    openLink(href)
  }, [])

  return (
    <a
      href={href}
      target='_blank'
      rel='noreferrer'
      onClick={handleClick}
    >
      {children}
    </a>
  )
}

export default ExternalLink