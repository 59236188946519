import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Title = styled.h1`
  text-align: center;
  text-transform: capitalize;
  position: relative;
  color: #FFF;
  margin: 40px auto;
  padding-bottom: 5px;
  font-size: 60px;
  font-size: min(60px, max(30px, calc(2vw + 2vh)));
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 1px;

  &:after {
    width: 100px;
    width: min(100px, max(50px, calc(2vw + 2vh)));
    height: 4px;
    background: ${({ underlineColor }) => underlineColor};
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`
Title.propTypes = {
  underlineColor: PropTypes.string
}

Title.defaultProps = {
  underlineColor: 'var(--ggMainColor)'
}

export default Title