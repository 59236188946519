import styled from 'styled-components'
import withScreenInfo from '~/components/withScreenInfo'
import withMFD from '~/components/withMFD'
import * as GeneralStyle from './generalStyleTemp'
import withTheme from '~/components/withTheme'
import Color from 'color'

export const Container = styled.div`
width: 100%;
background-color: #000;
position: fixed;
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
overflow: hidden;

&, * {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}`

export const BackGroundOpacityShim = withMFD(withScreenInfo(styled.div`
width: 100%;
position: relative;
height: 100%;
display: flex;
min-height: ${({ screen }) => screen.height}px;
flex-direction: column;
background-color: rgba(${props => props.color ? props.color[0] : 'transparent'}, 0.25);
background-image: ${GeneralStyle.releaseNotesRowHeaderBackgroundGradient};
background-image: url('${props => props.background || props?.mfd?.settings?.starcitizen?.background || GeneralStyle.backgroundImage}');
background-size: cover;
background-repeat: no-repeat;
background-position: center;
background-blend-mode: multiply;
opacity: 1;
`))

export const FlexWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height:100%;
position: absolute;
width: 100%;`

const RotateDeviceOverlayComponent = styled.div`
display: none;
/* @media (orientation: portrait) { */
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #000;

  &:before {
    content: 'Please rotate your device';
    /* content: '${({ screen }) => screen && `${screen.width}x${screen.height}, ${screen.orientation}, fullscreen: ${screen.fullscreen}`}'; */
    font-size: 2rem;
    color: #FFF;
  }
/* } */
`

export const RotateDeviceOverlay = withScreenInfo(
  ({ screen }) => {
    // if (screen.orientation === 'landscape' && screen.width < FRAGMENTS_BREAKPOINT) {
    //   return null
    //   // return <RotateDeviceOverlayComponent screen={screen} />
    // } else if (screen.orientation === 'portrait' && screen.width >= FRAGMENTS_BREAKPOINT) {
    //   return <RotateDeviceOverlayComponent screen={screen} />
    // } else {
    //   return null
    // }
    return screen.shouldDisplay.rotateDevice ? <RotateDeviceOverlayComponent screen={screen} /> : null
  }
)

export const Version = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: .85rem;
`

export const ReleaseNotesContainer = styled.div`
  position: relative;
  text-transform: uppercase;
  max-height: 80vh;
  max-height: calc(var(--vh) * 0.8);
  max-width: 95%;
  display: flex;
  flex-wrap: nowrap;
`

export const FourCorners = styled.span`
  pointer-events: none;
  width: 100%;
  height 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  ${({ corners }) => {
    corners = {
      color: 'rgba(43,237,230,1)',
      size: '10px',
      thickness: '1px',
      position: '-5px', // -(size / 2)
      ...corners
    }

    return `
    background-image: linear-gradient(to right, ${corners.color} ${corners.size}, transparent ${corners.size}), linear-gradient(to right, ${corners.color} ${corners.size}, transparent ${corners.size}), linear-gradient(to bottom, ${corners.color} ${corners.size}, transparent ${corners.size}), linear-gradient(to bottom, ${corners.color} ${corners.size}, transparent ${corners.size});
    background-size: 100% ${corners.thickness}, 100% ${corners.thickness}, ${corners.thickness} 100%, ${corners.thickness} 100%;
    background-position: ${corners.position} 0%, ${corners.position} 100%, 0% ${corners.position}, 100% ${corners.position};
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    `
  }}
`

// export const FourCorners = (props) => {
//   return (
//     <>
//       {React.Children.map(props.children, child => {
//         const clone = React.cloneElement(child, {
//           style: {
//             ...child.props.style,
//             position: 'relative'
//           },
//           children: [
//             ...(child.props.children.length ? child.props.children : [child.props.children]),
//             <FourCornersComponent />
//           ]
//         })
//         // clone.props.children.push(<FourCornersComponent />)
//
//         return clone
//       }
//       )}
//     </>
//   )
// }

export const TechCorners = withTheme(styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  top: 0px;
  ${props => props.direction}: 0px;
  border-top: 1px solid rgb(${({ theme }) => theme.color.primary});
  border-${props => props.direction}: 1px solid rgb(${({ theme }) => theme.color.primary});
  z-index: 1;
`)

export const ReleaseNotes = withTheme(styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  // height: 50%;
  width: 100%;
  background-image: ${GeneralStyle.releaseNotesBackgroundGradient};
  background-image: linear-gradient(${({ theme }) => {
    const darker = Color(`rgb(${theme.color.primary})`).darken(0.9).rgb().string()
    const dark = Color(`rgb(${theme.color.primary})`).darken(0.8).rgb().fade(0.75).string()
    return `${darker}, ${dark}`
  }});
  padding: 20px;
  border-top: 1px solid ${({ theme }) => Color(`rgb(${theme.color.primary})`).darken(0.8).rgb().string()};
  text-align: left;
  color: rgb(${({ theme }) => theme.color.primary});
  font-size: ${GeneralStyle.promptFontSize};
`)

export const ContentWrapper = styled.div`
  width:100%;
  overflow-y:auto;

  &::-webkit-scrollbar{
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal{
    height: 11px;
  }

  &::-webkit-scrollbar:vertical{
    width: 11px;
  }

  &::-webkit-scrollbar-thumb{
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
`

export const ReleaseNoteRowHeader = withTheme(styled.div`
  background-image: linear-gradient(${({ theme }) => {
    const darker = Color(`rgb(${theme.color.primaryHighlight})`).darken(0.9).rgb().string()
    const dark = Color(`rgb(${theme.color.primaryHighlight})`).darken(0.8).rgb().fade(0.75).string()
    return `${darker}, ${dark}`
  }});
  width: 160px;
  min-height: ${GeneralStyle.releaseNotesRowHeaderHeight};
  line-height: ${GeneralStyle.releaseNotesRowHeaderHeight};
  text-align: left;
  margin-left: 0px;
  padding: 0 8px;
  font-size: .9rem;
`)

export const ReleaseNoteVersionAndDate = styled.div`
  width: 100%;
  height: ${GeneralStyle.releaseNotesRowHeaderHeight};
  line-height: ${GeneralStyle.releaseNotesRowHeaderHeight};
  text-align: left;
  margin-left: 0px;
  text-indent: 8px;
  font-size: .9rem;
  margin-bottom: 8px;
`

export const ReleaseNoteVersionAndDateFlexReset = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
`

export const ReleaseNoteRow = styled.div`
  width: 100%;
  font-size: .85rem;

  li {
    font-size: .85rem;
  }
`

export const KeyBindNote = styled.div`
  text-transform: uppercase;
`
