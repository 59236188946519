import ReactGA from 'react-ga'

let initialized = false;
export const initGA = () => {
  if(initialized) {
    return;
  }
  ReactGA.initialize('UA-132553513-1', {
    debug: false
  });
  initialized = true;
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

export const logComponentView = (componentNameAsPage) => {
  ReactGA.set({ page: componentNameAsPage })
  ReactGA.pageview(componentNameAsPage)
}

export const logEvent = (category = '', action = '') => {
  if (category && action) {
    ReactGA.event({ category, action })
  }
}

export const setDimension = (value) => {
  ReactGA.set(value)
}

export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal })
  }
}
