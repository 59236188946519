import styled from 'styled-components'
import { COLORS } from '../utils'
import ExternalLink from '../../ExternalLink'
import { Title as T } from '~/components/common/Title'
import { Button as B } from '~/components/common/Button'

export { default as PulsingLogo } from './PulsingLogo'
// Black Bold Extrabold Thing
export const Backdrop = styled.section`
  font-family: proxima nova;
  color: #B9C4D7;
  font-size: 20px;
  font-size: min(20px, max(16px, calc(1vw + 1vh)));
  line-height: 1.2;
  letter-spacing: 0.2px;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: ${({ modal }) => modal ? 'fixed' : 'relative'};
  z-index: ${({ priority }) => 9999 + (priority || 0)};
  background: rgba(0, 0, 0, 0.5);
  ${({ type }) => {
    const colors = COLORS[type] || COLORS.default

    return `
      --announcement-main-color: ${colors.top};
      --announcement-top-color: var(--announcement-main-color);
      --announcement-bottom-color: ${colors.bottom};
      --announcement-color-matrix: ${colors.matrix};
    `
  }}
`

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: var(--ggBackground);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  padding: 15px;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  ${({ modal, type }) => modal && `
    max-width: calc(100% - 50px);
    max-height: calc(100% - 50px);
    width: 900px;
    height: 720px;
    border: 4px solid var(--announcement-main-color);
    border-radius: 24px;
  `}
`

export const ScrollingContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`

export const Title = styled(T)`
  &:after {
    background-color: var(--announcement-main-color);
  }
`

export const Subtitle = styled.h2`
  text-transform: uppercase;
  color: var(--announcement-main-color);
  margin: 0 0 20px 0;
  font-size: 20px;
  font-size: min(20px, max(16px, calc(1vw + 1vh)));
  font-weight: 600;
  line-height: 1.2;
`

export const ChildrenContainer = styled.div`
  color: #B9C4D7;
  font-size: 20px;
  font-size: min(20px, max(16px, calc(1vw + 1vh)));
  line-height: 1.2;
`

export const Paragraph = styled.p`
  color: #B9C4D7;
  font-size: 20px;
  font-size: min(20px, max(16px, calc(1vw + 1vh)));
  line-height: 1.2;
`

export const Highlight = styled.span`
  color: var(--announcement-main-color);
`

export const ContentContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  flex: 1;
`

export const Button = B

export const Link = ({
  href,
  children,
  ...rest
}) => {
  return (
    <ExternalLink
      href={href}
    >
      <Button
        {...rest}
      >
        {children}
      </Button>
    </ExternalLink>
  )
}

export const Toast = styled.button`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  border-radius: 16px;
  background-color: #A6A6A6;
  margin: 0;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #FFF;
  width: 250px;
  max-width: 80%;
`

Toast.defaultProps = {
  children: 'Copied to clipboard'
}

export const Spacer = styled.span`
  display: block;
  height: calc(2vw + 2vh);
  max-height: 10vh;
  width: 100%;
`