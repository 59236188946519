import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  font-family: "Whitney A", "Whitney B", -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,sans-serif;
  width: 100%;
  min-height: 100vh;
  min-height: var(--vh);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  color: #fff;
  background: var(--ggBackground);
  background-size:cover;
  background-position: center;
  font-size: 18px;

  ${props => props.fixed && `
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
  `}

  &, * {
    box-sizing: border-box;
  }
`

export const Logo = styled.img`
  width: 25rem;
  max-width: 20%;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  display: none;
`

export const bounce = keyframes`
  0% { transform: translateY(0px); background: #49E69B; }
  25% { transform: translateY(-30px); background: #49C0BA; }
  75% { transform: translateY(0px); background: #49E69B; }
  100% { transform: translateY(0px); background: #49E69B; }
`

export const Dot = styled.span`
  margin: ${props => props.spaceBetween ? `0 ${props.spaceBetween}px` : '0 10px'};
  display: block;
  width: ${props => (props.width ? `${props.width}px` : '20px')};
  height: ${props => (props.height ? `${props.height}px` : '20px')};
  border-radius: 50%;
  background: ${props => (props.pColor ? props.pColor : '#555')};
  animation: ${bounce} 1s ease-in-out infinite;

  &:nth-child(2) {
    animation-delay: 333ms;
  }

  &:nth-child(3) {
    animation-delay: 666ms;
  }
`

export const BounceLoader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
`

export const Div = styled.div`
  width: auto;
`
