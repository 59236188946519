import { ADD_ANNOUNCEMENT, REMOVE_ANNOUNCEMENT } from './actionTypes'
import { getAnnouncement } from '~/utils/announcements'

export const addAnnouncement = (payload) => {
  return {
    type: ADD_ANNOUNCEMENT,
    payload: getAnnouncement(payload)
  }
}

export const removeAnnouncement = (payload) => {
  return {
    type: REMOVE_ANNOUNCEMENT,
    payload: getAnnouncement(payload)
  }
}
