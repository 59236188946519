import { useEffect } from 'react'
import {LAST_LOCATION} from "~/config/settings";
import {Router, useRouter} from "next/router";
import {useAuth} from "@clerk/clerk-react";

const getRedirectToLastUrl = () => {
  let lastUrl
  try {
    lastUrl = localStorage.getItem(LAST_LOCATION)
  }catch(err) {
    // nothing here
  }
  return lastUrl || "/shardlibrary"
}

export const useRedirectToLastUrl = () => {
  const router = useRouter()
  const {isLoaded, isSignedIn} = useAuth()

  useEffect(() => {
    if(isLoaded && isSignedIn) {
      const lastUrl = getRedirectToLastUrl()
      if(router.asPath !== lastUrl) {
        router.replace(getRedirectToLastUrl())
      }
    }
  }, [isLoaded, isSignedIn])
}

export const useSaveLastUrl = () => {
  const router = useRouter()
  useEffect(() => {
    const handleRouteComplete = (route) => {
      // Remove basepath from url being saved
      const url = route.replace(new RegExp(`^${router.basePath}`), "")
      // Check to see if it's a valid url to save
      if (url.startsWith("/shardlibrary")) {
        localStorage.setItem(LAST_LOCATION, url)
      }
    }

    Router.events.on("routeChangeComplete", handleRouteComplete)

    return () => {
      Router.events.off("routeChangeComplete", handleRouteComplete)
    }
  }, [router])
}
