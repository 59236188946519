import {memo} from 'react'
import Rive from '@rive-app/react-canvas'
import {useNextConfig} from "~/hooks/useNextConfig";

const LoadingAnimation = (props) => {
	const config = useNextConfig()
	return (
		<Rive
			style={{
				maxWidth: '480px',
				maxHeight: '480px',
				width: '200px',
				height: '200px',
				margin: '20px'
			}}
			{...props}
			src={config.assetPrefix + "/static/gg_loader.riv"}
			stateMachines="greenLoaderSM"
		/>
	)
}

export default memo(LoadingAnimation)