import { useRef, useEffect, useCallback } from 'react'
import ThemeProvider from '~/providers/ThemeProvider'
import MfdProvider from '~/providers/MfdProvider'
import { RotateDeviceOverlay } from '~/styled'
import { useSelector } from 'react-redux'
import { GLOBAL } from '~/config/settings'

const EVENTS = [
  // 'mousedown',
  'mouseup',
  // 'touchstart',
  // 'touchmove',
  'touchend'
]

function Game (props) {
  const source = useSelector((state) => {
    return state.userSource.source
  })
  const NoSleep = useRef()
  const enableNoSleep = useCallback((e) => {
      if (typeof window !== 'undefined' && source !== 'app' && !GLOBAL.noSleep) {
        try {
          // eslint-disable-next-line new-cap
          GLOBAL.noSleep = new NoSleep.current()
          GLOBAL.noSleep.enable()

          EVENTS.map(event => {
            window.removeEventListener(event, enableNoSleep)
          })
        } catch {
          GLOBAL.noSleep = null
        }
      }
  }, [source])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('nosleep.js')
        .then(module => {
          NoSleep.current = module.default
          EVENTS.map(event => {
            window.addEventListener(event, enableNoSleep)
          })
        })
    }
  }, [])

  return (
      <MfdProvider>
        <ThemeProvider>
          {props.children}
          {!props.allowDeviceRotation && <RotateDeviceOverlay />}
        </ThemeProvider>
      </MfdProvider>
  )
}

Game.defaultProps = {
  allowDeviceRotation: true
}

export default Game
