import { useEffect } from 'react'
import { useSelector } from "react-redux";
import { GrowthBook } from '@growthbook/growthbook'

const gb = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_KEY,
  enableDevMode: Boolean(process.env.NEXT_PUBLIC_GROWTHBOOK_DEVMODE),
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
  },
})

// Load features asynchronously
const initialized = gb.loadFeatures()

// Include jiraHost for any FF that needs to be rolled out in stages
export const booleanFlag = async (flag) => {
  await initialized
  return gb.isOn(flag)
}

export const stringFlag = async (
    flag,
    defaultValue,
) => {
  await initialized
  return gb.getFeatureValue(flag, defaultValue)
}

export const numberFlag = async (
    flag,
    defaultValue,
) => {
  await initialized
  return gb.getFeatureValue(flag, defaultValue)
}

export const jsonFlag = async (
    flag,
    defaultValue,
) => {
  await initialized
  return gb.getFeatureValue(flag, defaultValue)
}

export const useFeatureFlags = () => {
  const user = useSelector((state) => state.authenticationReducer.user);

  useEffect(() => {
    if (user) {
      gb.setAttributes(user)
    }
  }, [user]);
}
