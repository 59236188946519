import {useEffect} from "react";
import {useUser} from "@clerk/clerk-react";
import {usePostHog} from "posthog-js/react";

export const usePosthogIdentify = () => {
  const posthog = usePostHog()
  const { user } = useUser()

  useEffect(() => {
    if(user) {
      posthog.identify(user.externalId, {
        username: user.publicMetadata.username,
        clerk_id: user.id,
        has_glasspass: user.publicMetadata.has_glasspass,
        glasspass_type: user.publicMetadata.glasspass_type,
        accepts_marketing: user.publicMetadata.accepts_marketing,
        is_staff: user.publicMetadata.is_staff,
        created_at: user.createdAt?.toISOString()
      })
    }
  }, [user]);
}
