import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import defaultReducers from './reducers'
import { useMemo } from 'react'
import {isDevelopment} from "~/helpers/environment";

const composeEnhancers = (typeof window !== 'undefined' && isDevelopment) ? composeWithDevTools({
  // features: {}
}) : compose

function createReducerManager (initialReducers = defaultReducers) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers }

  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers)

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = []

  // Keep state and return unchanged state for other reducers to handle it
  const noopReducer = (state = {}) => state

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        state = { ...state }
        for (const key of keysToRemove) {
          delete state[key]
        }
        keysToRemove = []
      }

      // Delegate to the combined reducer
      return combinedReducer(state, action)
    },

    // Adds a new reducer with the specified key
    add: (key, reducer, replace = false) => {
      // Only run if there is a key, the reducer is different than the noopReducer to keep state and explicitly replacing it
      if (!key || ((reducers[key] && reducers[key] !== noopReducer) && !replace)) {
        return
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers)
    },

    // Removes a reducer with the specified key
    remove: (key, removeState = true) => {
      if (!key || !reducers[key]) {
        return
      }

      if (removeState) {
        // Remove it from the reducer mapping
        delete reducers[key]

        // Add the key to the list of keys to clean up
        keysToRemove.push(key)
      } else {
        reducers[key] = noopReducer
      }

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers)
    }
  }
}

let store

function configureStore (initialState) {
  const reducerManager = createReducerManager(defaultReducers)

  // Create a store with the root reducer function being the one exposed by the manager.
  store = createStore(
    reducerManager.reduce,
    initialState,
    composeEnhancers(
      // applyMiddleware(thunk, LogRocket.reduxMiddleware())
      applyMiddleware(thunk)
    )
  )

  // Optional: Put the reducer manager on the store so it is easily accessible
  store.reducerManager = reducerManager

  return store
}

function useStore (initialState) {
  const store = useMemo(() => configureStore(initialState), [initialState])
  return store
}

export { store, useStore }

const init = initialState => configureStore(initialState)

export default init
