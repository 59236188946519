import { SET_VARIANT_SHARDS } from '~/store/actions/actionTypes'

const initialState = {
  variantLinks: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_VARIANT_SHARDS:
    return {
      ...state,
      variantLinks: action.payload
    }

  default: return state
  }
}

export default reducer
