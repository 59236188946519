import { SET_VARIANT_SHARDS } from './actionTypes'
import {
  storage
} from '~/helpers'

export const setVariantShards = (variantLinks) => {
  storage.setItem('selectedVariants', JSON.stringify([...variantLinks]))
  return {
    type: SET_VARIANT_SHARDS,
    payload: variantLinks
  }
}
