import React from 'react'
import { MfdContext } from '~/contexts'

export default function withMFD(WrappedComponent) {
  return function ComponentWrapper (props) {
    return (
      <MfdContext.Consumer>
        {(mfdProps) => {
          const { mfd, connected, settings } = mfdProps
          return mfd ? (
            <WrappedComponent
              {...props}
              {...mfdProps}
              mfd={mfd}
              connected={connected}
              settings={settings}
            />
          ) : (
            null
          )
        }}
      </MfdContext.Consumer>
    )
  }
}
