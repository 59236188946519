import { useEffect } from 'react'
import useAnnouncements from './useAnnouncements'
import { NO_INTERNET_CONNECTION } from '~/utils/announcements'

const useInternetConnection = () => {
  const { addAnnouncement, removeAnnouncement } = useAnnouncements()

  useEffect(() => {
    const handleConnectionChange = () => {
      if (typeof window !== 'undefined') {
        if (navigator.onLine) {
          removeAnnouncement(NO_INTERNET_CONNECTION)
        } else {
          addAnnouncement(NO_INTERNET_CONNECTION)
        }
      }
    }

    if (typeof window !== 'undefined') {
      handleConnectionChange()
      window.addEventListener('online', handleConnectionChange)
      window.addEventListener('offline', handleConnectionChange)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('online', handleConnectionChange)
        window.removeEventListener('offline', handleConnectionChange)
      }
    }
  }, [])
}

export default useInternetConnection