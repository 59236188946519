export const faintBorderColor = '#103b3b'
export const fontColor = '#aafffc'
export const backgroundImage = './static/apps/starCitizen/shards/flightControls/starfield-1.png'

// export const promptWidth = '60vw'
// export const promptMinWidth = '450px'
export const promptWidth = '450px'
// export const promptMaxWidth = '60vw'
export const promptMaxWidth = 'calc(var(--vw) * 0.6)'
export const promptMaxWidthForMobile = '750px' // promptWidth / promptMaxWidth (when percentage or viewport size) * 100
export const promptFontSize = '1.13rem'
export const promptBackgroundGradient = 'linear-gradient(#0a1214, rgba(12, 33, 34, 0.7))'
export const releaseNotesBackgroundGradient = 'linear-gradient(#0a1214, rgba(42, 73, 74, 0.5))'
export const releaseNotesBackgroundGradientReversed = 'linear-gradient(rgba(42, 73, 74, 0.5), #0a1214)'
export const releaseNotesRowHeaderBackgroundGradient = 'linear-gradient(#162b2d, #193334)'
export const releaseNotesRowHeaderHeight = '30px'
export const releaseNotesTopMargin = '20px'
export const releaseNotesBottomMargin = '30px'

export const promptButtonWidth = '75%'
export const activeBorderColor = '#2bede6'
export const activeTextColor = '#2bede6'
export const promptButtonBackgroundGradient = 'linear-gradient(#0f2627, rgba(16, 51, 52, 0.8))'

// Header items
export const titleBarHeight = '35px'
export const logoHeaderWidth = '250px'
export const headerHeight = '145px'
export const mainMenuHeight = '80px'
export const greenCheckImage = './static/apps/starCitizen/shards/flightControls/greenCheck.png'
export const activeFontColor = 'rgba(181,255,252,1)'
