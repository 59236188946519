import React, { Component } from 'react'
import { ScreenContext } from '~/contexts'

const withScreenInfo = (WrappedComponent) => {
  return class withScreenInfo extends Component {
    render () {
      return (
        <ScreenContext.Consumer>
          {(screen) => {
            return <WrappedComponent {...this.props} screen={screen} />
          }}
        </ScreenContext.Consumer>
      )
    }
  }
}

export default withScreenInfo
