import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Button = styled.button`
  position: relative;
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
  color: #FFF;
  background-color: #525C7D;
  background-color: rgba(82, 92, 125, 0.5);
  border: none;
  border-radius: 5px;
  width: 150px;
  margin: 10px;
  max-width: calc(100% - 20px);
  padding: 15px 10px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
  }

  ${({ primary }) => primary && `
    background-color: transparent;
    background-image: var(--ggButtonLinearGradient);
  `}

  ${({ outline }) => outline && `
    background-color: transparent;
    
    &:after {
      border: 2px solid rgba(82, 92, 125, 0.5);
    }
  `}

  &:hover {
    filter: brightness(0.9);
  }
`

Button.propTypes = {
  primary: PropTypes.bool,
  outline: PropTypes.bool,
}

Button.defaultProps = {
  primary: false,
  outline: false,
}

export default Button