import { FORCE_UPDATE } from '~/store/actions/actionTypes'
import authenticationReducer from './authentication'
import userSource from './userSource'
import announcementsReducer from './announcement'
import shardLibrary from './shardLibrary'

const forceUpdateInitialState = +new Date()

export default {
  forceUpdateReducer: (state = forceUpdateInitialState, { type }) => {
    if (type === FORCE_UPDATE) return +new Date()
    return state
  },
  userSource,
  authenticationReducer,
  announcementsReducer,
  shardLibrary
}
