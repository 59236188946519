import { createContext } from 'react'
import { throttle } from '~/helpers'
import { FRAGMENTS_BREAKPOINT } from '~/config/settings'

export const WebRTCContext = createContext()

export const GameContext = createContext()

export const MfdContext = createContext({})

export const ThemeContext = createContext({
  setTheme: () => {}
})

export const ScreenContext = createContext({
  width: (typeof window !== 'undefined' ? window.innerWidth : 1),
  height: (typeof window !== 'undefined' ? window.innerHeight : 1),
  orientation: (typeof window !== 'undefined' ? (matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape') : 'portrait'),
  standalone: (typeof window !== 'undefined' ? (matchMedia('(display-mode: standalone)').matches) : false),
  fullscreen: false,
  shouldDisplay: {
    fragments: true,
    shards: false,
    rotateDevice: false
  }
})

function onScreenResize (limit = 0) {
  const callback = () => {
    clearTimeout(this.fullscreenTimeout)
    const fullscreen = !!document.fullscreenElement
    if (fullscreen === this.state.screen.fullscreen) {
      const screen = {
        width: window.innerWidth,
        height: window.innerHeight,
        orientation: matchMedia('(orientation: portrait)').matches
          ? 'portrait'
          : 'landscape',
        standalone: matchMedia('(display-mode: standalone)').matches,
        fullscreen,
        shouldDisplay: {
          fragments: true,
          shards: false,
          rotateDevice: false
        }
      }

      if (screen.width > FRAGMENTS_BREAKPOINT && screen.height > FRAGMENTS_BREAKPOINT) {
        if (screen.orientation === 'landscape') {
          screen.shouldDisplay = {
            fragments: false,
            shards: true,
            rotateDevice: false
          }
        }
      } else if (screen.orientation === 'landscape') {
        screen.shouldDisplay = {
          fragments: true,
          shards: false,
          rotateDevice: true
        }
      }

      this.setState({ screen })
    } else {
      this.fullscreenTimeout = setTimeout(() => {
        this.setState({
          screen: {
            ...this.state.screen,
            fullscreen
          }
        })
        this.onScreenResize()
      }, limit)
    }
  }

  return throttle(callback, limit, true)
}

ScreenContext.methods = {
  onScreenResize
}
