import { Paragraph, Button, Link, Highlight } from '~/components/common/Announcement/components'
import { useSelector } from 'react-redux'
import useAnnouncements from '~/hooks/useAnnouncements'
import { store } from '~/store'
import {
  addAnnouncement as addAnnouncementAction
} from '~/store/actions'
import { SUPPORT_URL } from '~/config/settings'
import { useRouter } from "next/router";

const types = {
  default: 'default',
  error: 'error',
  warning: 'warning',
}

const titles = {
  default: 'Beep Boop',
  error: 'Error',
  warning: 'Warning',
}

const priorities = {
  low: 0,
  medium: 1,
  high: 2
}

export const UNKNOWN = 'UNKNOWN'
export const FOUR_O_FOUR = 'FOUR_O_FOUR'
export const SERVER_ERROR = 'SERVER_ERROR'
export const DEVICE_NOT_SUPPORTED = 'DEVICE_NOT_SUPPORTED'
export const WEBVIEW_NOT_SUPPORTED = 'WEBVIEW_NOT_SUPPORTED'
export const BROWSER_NOT_SUPPORTED = 'BROWSER_NOT_SUPPORTED'
export const NOT_OFFICIALLY_SUPPORTED = 'NOT_OFFICIALLY_SUPPORTED'
export const HOST_CONNECTION_ERROR = 'HOST_CONNECTION_ERROR'
export const NO_INTERNET_CONNECTION = 'NO_INTERNET_CONNECTION'

export const ANNOUNCEMENTS = Object.freeze({
  [UNKNOWN]: {
    id: UNKNOWN,
    type: types.error,
    title: titles.error,
      modal: false,
    subTitle: 'Something went wrong.',
    children: () => (
      <>
        <Paragraph>
          {'Page not found'}
        </Paragraph>
        <Button
          primary
          onClick={() => (location = location.origin)}
        >
          {'Retry'}
        </Button>
      </>
    )
  },
  [FOUR_O_FOUR]: {
    id: FOUR_O_FOUR,
    type: types.error,
    title: titles.error,
      modal: false,
    subTitle: '404',
    children: () => (
      <>
        <Paragraph>
          {'Page not found'}
        </Paragraph>
        <Button
          primary
          onClick={() => (location = location.origin)}
        >
          {'Retry'}
        </Button>
      </>
    )
  },
  [SERVER_ERROR]: {
    id: SERVER_ERROR,
    type: types.error,
    title: titles.error,
      modal: false,
    subTitle: 'Servers offline',
    children: () => {
      return (
        <>
          <Paragraph>
            {'The GameGlass servers are temporarily offline.'}
            <br/>
            {'We are working on getting the servers back online.'}
            <br/>
            {'You can check the status of our services here.'}
          </Paragraph>

          <Button
            primary
            onClick={() => (location = location.origin)}
          >
            {'Retry'}
          </Button>

          <Link
            href={`${SUPPORT_URL}/en/articles/7231677-gameglass-service-disruptions`}
          >
            {'Get help'}
          </Link>
        </>
      )
    }
  },
  [DEVICE_NOT_SUPPORTED]: {
    id: DEVICE_NOT_SUPPORTED,
    type: types.error,
    title: titles.error,
      modal: false,
      subTitle: 'Compatibility issue',
    children: () => {
      return (
        <>
          <Paragraph>
            {'Unfortunately, this device is not supported by GameGlass.'}
            <br/>
            {'You can find a list of supported devices and operating systems on our support page.'}
          </Paragraph>
          <Link
            href={`${SUPPORT_URL}/en/articles/7231929-minimum-requirements`}
          >
            {'Get help'}
          </Link>
        </>
      )
    }
  },
  [WEBVIEW_NOT_SUPPORTED]: {
    id: WEBVIEW_NOT_SUPPORTED,
    type: types.error,
    title: titles.error,
      modal: false,
      subTitle: 'Browser version issue',
    children: () => {
      return (
        <>
          <Paragraph>
            {'This Webview is not supported to run GameGlass.'}
            <br/>
            {'Try using a standalone browser.'}
            <br/>
            {'You can find a list of supported browsers on our support page.'}
          </Paragraph>
          <Link
            href={`${SUPPORT_URL}/en/articles/7231929-minimum-requirements`}
          >
            {'Get help'}
          </Link>
        </>
      )
    }
  },
  [BROWSER_NOT_SUPPORTED]: {
    id: BROWSER_NOT_SUPPORTED,
    type: types.error,
    title: titles.error,
      modal: false,
      subTitle: 'Browser version issue',
    children: () => {
      return (
        <>
          <Paragraph>
            {'Unfortunately, this browser version is not supported by GameGlass.'}
            <br/>
            {'You can find a list of supported browsers on our support page.'}
          </Paragraph>
          <Link
            href={`${SUPPORT_URL}/en/articles/7231929-minimum-requirements`}
          >
            {'Get help'}
          </Link>
        </>
      )
    }
  },
  [NOT_OFFICIALLY_SUPPORTED]: {
    id: NOT_OFFICIALLY_SUPPORTED,
    type: types.warning,
    title: titles.warning,
    priority: priorities.high,
    modal: true,
    subTitle: 'You may encounter issues on this device',
    children: () => {
      const { removeAnnouncement } = useAnnouncements()

      return (
        <>
          <Paragraph>
            {'This device or browser does not meet the official support requirements.'}
            <br/>
            {'If you proceed, be advised, you may experience some issues while running GameGlass.'}
          </Paragraph>

          <Link
            href={`${SUPPORT_URL}/en/articles/7231929-minimum-requirements`}
          >
            {'Get help'}
          </Link>

          <Button
            primary
            onClick={() => removeAnnouncement(NOT_OFFICIALLY_SUPPORTED)}
          >
            {'Proceed'}
          </Button>
        </>
      )
    }
  },
  [HOST_CONNECTION_ERROR]: {
    id: HOST_CONNECTION_ERROR,
    type: types.error,
    title: titles.error,
    priority: priorities.medium,
    modal: true,
    subTitle: 'Cannot connect to host',
    children: () => {
      const router = useRouter()
      const user = useSelector((state) => {
        return state.authenticationReducer.user
      })
    const { removeAnnouncement } = useAnnouncements()

      return (
        <>
          <Paragraph>
            Make sure your GameGlass Host is running on your PC.
            <br/>
            You can download the Host from gameglass.gg website on your PC.
            <br/>
            {(user && user.username) && (
              <>
                You are currently logged in as <Highlight>{user.username}</Highlight>.
              </>
            )}
          </Paragraph>

            <Button
                primary
                onClick={() => window.location.reload()}
            >
                Retry
            </Button>

          <Button
            outline
            onClick={() => {
                removeAnnouncement(HOST_CONNECTION_ERROR)
                router.push("/logout")
            }}
          >
            Logout
          </Button>

          <Link
            href={`${SUPPORT_URL}/en/articles/7231972-cannot-connect-to-host-error`}
          >
            Get help
          </Link>
        </>
      )
    }
  },
  [NO_INTERNET_CONNECTION]: {
    id: NO_INTERNET_CONNECTION,
    type: types.error,
    title: titles.error,
    priority: priorities.high,
    modal: true,
    subTitle: 'No internet connection',
    children: `Make sure you are connected to the internet in order to run GameGlass. Check your internet connection.`
  },
})

export const getAnnouncement = (request) => {
  if (typeof request === 'string' && ANNOUNCEMENTS[request]) {
    return ANNOUNCEMENTS[request]
  } else if (Object.values(ANNOUNCEMENTS).includes(request)) {
    return request
  }
  return ANNOUNCEMENTS[UNKNOWN]
}

export const addAnnouncement = (request) => {
  const announcement = getAnnouncement(request)

  store.dispatch(addAnnouncementAction(announcement))
}

export default ANNOUNCEMENTS