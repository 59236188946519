import { Component } from 'react'
import { ThemeContext } from '~/contexts'

const withTheme = WrappedComponent => {
  return class withTheme extends Component {
    render () {
      const {
        innerRef,
        ...props
      } = this.props

      return (
        <ThemeContext.Consumer>
          {context => {
            const { theme, setTheme } = context
            return (
              <WrappedComponent
                {...props}
                ref={innerRef}
                theme={theme}
                setTheme={setTheme}
              />
            )
          }}
        </ThemeContext.Consumer>
      )
    }
  }
}

export default withTheme
