import {useEffect, useState} from 'react'
import {ThemeContext} from '~/contexts'
import {withRouter} from 'next/router'
import withMFD from '~/components/withMFD'
import {getNestedProp} from '~/helpers'
import deepEqual from 'fast-deep-equal'

function ThemeProvider (props) {
  const getCurrentTheme = () => {
    const game = props.router.asPath.split('/').filter(_ => _)[0]

    return getNestedProp(
        props,
        `mfd.settings[${game}].theme`
    )
  }

  const [theme, setTheme] = useState(getCurrentTheme())

  useEffect(() => {
    const currentTheme = getCurrentTheme()

    if (!deepEqual(theme, currentTheme)) {
      setTheme(() => currentTheme)
    }
  }, [props.mfd, props.mfd.settings])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme
      }}
    >
      {/* <StyledProvider theme={theme}> */}
      {props.children}
      {/* </StyledProvider> */}
    </ThemeContext.Provider>
  )
}

export default withMFD(withRouter(ThemeProvider))
