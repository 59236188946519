import { ADD_ANNOUNCEMENT, REMOVE_ANNOUNCEMENT } from '~/store/actions/actionTypes'

const initialState = {
  announcements: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ADD_ANNOUNCEMENT: {
      // If announcement already exists, just return same state
      if(state.announcements.find(a => a.id === action.payload.id)) {
          return state
      }

    return {
      ...state,
      announcements: [...state.announcements, action.payload]
          .sort((a,b) => (a.priority || 0) - (b.priority || 0))
    }
  }

  case REMOVE_ANNOUNCEMENT: {
    return {
      ...state,
      announcements: [...state.announcements
          .filter(announcement => announcement.id !== action.payload.id)]
    }
  }

  default: return state
  }
}

export default reducer
