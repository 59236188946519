/* eslint-disable no-undef */
import { useEffect } from 'react'
import { isProduction } from "~/helpers/environment";
import { AnalyticsBrowser } from '@segment/analytics-next'
import {useUser} from "@clerk/clerk-react";

export const useAnalytics = () => {
  const { user } = useUser()

  useEffect(() => {
    if(isProduction) {
      // Segment
      if(!window.segment) {
        window.segment = AnalyticsBrowser.load({ writeKey:  process.env.NEXT_PUBLIC_SEGMENT_KEY })
        window.segment.page();
      }
    }

  }, []);

  useEffect(() => {
    if(user) {
      // Segment
      window.segment?.identify(user.externalId, {
        email: user.primaryEmailAddress.emailAddress
      })
    }
  }, [user]);
}
