import {
  PulsingLogo,
  Backdrop,
  Container,
  Title,
  ScrollingContainer,
  ContentContainer,
  ChildrenContainer,
  Subtitle,
  Button,
  Link
} from './components'
import { SUPPORT_URL } from '~/config/settings'

const Announcement = ({
  type = 'error',
  modal = false,
  title = 'Error',
  priority = 0,
  subTitle = 'An error has occurred.',
  children = () => {
    return (
      <>
        <Button
          primary
          onClick={() => (location = location.origin)}
        >
          {'Retry'}
        </Button>
        <Link
          href={SUPPORT_URL}
        >
          {'Get help'}
        </Link>
      </>
    )
  }
}) => {
  const Children = children
  return (
    <Backdrop
      type={type}
      modal={modal}
      priority={priority}
    >
      <Container
        type={type}
        modal={modal}
      >
        <ScrollingContainer>
          <ContentContainer>
            <PulsingLogo type={type} />
            {title && <Title>{title}</Title>}
            {subTitle && <Subtitle>{subTitle}</Subtitle>}
            {children && (
              <ChildrenContainer>
                {typeof children === 'function' ? <Children /> : children}
              </ChildrenContainer>
            )}
          </ContentContainer>
        </ScrollingContainer>

      </Container>
    </Backdrop>
  )
}

export default Announcement