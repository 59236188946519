export const COLORS = {
  default: {
    top: 'var(--ggMainColor)',
    bottom: 'var(--ggSecondaryColor)',
    matrix: '1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0'
  },
  warning: {
    top: '#FFD569', 
    bottom: '#FFC047',
    matrix: '0 0 0 0 1 0 0 0 0 0.827451 0 0 0 0 0.392157 0 0 0 0.65 0'
  },
  error: {
    top: 'var(--ggErrorColor)', 
    bottom: 'var(--ggErrorColor)',
    matrix: '0 0 0 0 1 0 0 0 0 0.364706 0 0 0 0 0.278431 0 0 0 0.65 0'
  },
}