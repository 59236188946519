import {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react'
import withScreenInfo from '~/components/withScreenInfo'

const InputMask = ({ screen }) => {
  if (!screen.shouldDisplay.inputMask || !0) {
    return null
  }

  const [value, setValue] = useState('')
  const interval = useRef()

  const intervalCallback = useCallback(() => {
    const target = document.activeElement

    if (target && value !== target.value) {
      setValue(target.value)
    }
  }, [value])

  useEffect(() => {
    interval.current = setInterval(intervalCallback, 100)

    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return (
    <div
      css={`
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
      `}
    >
      <div
        css={`
          width: 200px;
          max-width: 90%;
          font-size: 25px;
          padding: 10px;
          border-radius: 5px;
          pointer-events: none;
          background: #FFF;
          box-shadow: 1px 1px 2px #FFF;
        `}
      >
        {value}
      </div>
      {/* <input
        onChange={onChange}
        value={value}
        css={`
          font-size: 25px;
          padding: 10px;
          border-radius: 5px;
        `}
      /> */}
    </div>
  )
}

export default withScreenInfo(InputMask)
