import { detect } from 'detect-browser'
import { useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'
import { setGlobalError } from '~/hooks/useGlobalError'
import { GLOBAL } from '~/config/settings'
import useAnnouncements from './useAnnouncements'
import announcements from '~/utils/announcements'
import { coerce, lt } from 'semver'

/*
Fire devices
KFTT
KFJWA (WAN)
KFJWI (Wi-Fi)
KFSOWI
KFARWI
KFASWI
KFMEWI
KFTBWI
KFGIWI
KFDOWI
KFSUWI
KFKAWI
KFMAWI
KFONWI
*/

const versionLowerThan = (version, targetVersion) => {
  try {
    return lt(coerce(version), coerce(targetVersion))
  } catch (error) {
    return Number(version) < Number(targetVersion)
  }
}

const runCompatibilityCheck = (device, addAnnouncement) => {
  try {
    const RULES = Object.freeze({
      isSafariLessThan: (targetVersion) => {
        return device.browser.name === 'Safari' && versionLowerThan(device.browser.version, targetVersion)
      },
      isIOSLessThan: (targetVersion) => {
        return device.os.name === 'iOS' && versionLowerThan(device.os.version, targetVersion)
      },
      isAndroidLessThan: (targetVersion) => {
        return device.os.name === 'Android' && versionLowerThan(device.os.version, targetVersion)
      },
      isAmazonLessThan: (targetVersion) => {
        return (device.device.vendor === 'Amazon' && device.os.name === 'Android') && versionLowerThan(device.os.version, targetVersion)
      },
      isUnsupportedWebView: () => {
        return (device.name === 'chromium-webview' || ['Chrome WebView', 'Android Browser'].includes(device.browser.name)) && (device.engine.name === 'Blink' && versionLowerThan(device.engine.version, 60))
      },
      isUnsupportedBrowser: () => {
        return (device.engine.name === 'Blink' && versionLowerThan(device.engine.version, 60)) || (device.engine.name === 'WebKit' && versionLowerThan(device.engine.version, '604.2.4'))
      },
      isNotOfficiallySupported: () => {
        return (
          RULES.isSafariLessThan(12)
          || RULES.isAndroidLessThan(7)
          || ((device.engine.name === 'Blink' || ['Edge', 'Chrome'].includes(device.browser.name)) && versionLowerThan(device.engine.version, 80))
        )
      }
    })

    if (
      RULES.isSafariLessThan(11)
      || RULES.isIOSLessThan(11)
      || RULES.isAndroidLessThan('4.4')
      || RULES.isAmazonLessThan(5)
    ) {
      return addAnnouncement(announcements.DEVICE_NOT_SUPPORTED)
    }
    
    if (RULES.isUnsupportedWebView()) {
      return addAnnouncement(announcements.WEBVIEW_NOT_SUPPORTED)
    }

    if (RULES.isUnsupportedBrowser()) {
      return addAnnouncement(announcements.BROWSER_NOT_SUPPORTED)
    }

    const notSupportedString = 'not-supported'
    if (RULES.isNotOfficiallySupported()) {
      const notSupported = localStorage.getItem(notSupportedString)

      notSupported !== device.ua && addAnnouncement(announcements.NOT_OFFICIALLY_SUPPORTED)

      return localStorage.setItem(notSupportedString, device.ua)
    } else {
      return localStorage.removeItem(notSupportedString)
    }
  } catch (error) {
    setGlobalError(error)
  }
}

export const detectDevice = (addAnnouncement) => {
  const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : undefined
  // We merge both libraries results to have better understanding
  if (typeof window !== 'undefined') {
    const deviceRegexes = [
      // Amazon vendor regex. First match is to check if it's android, then in there's a string KFxxx
      [/(Android\s\d\.?\d+?\.?\d+?;\s)(kf[A-z]+)(\sbuild\/|\))(.+silk\/)?/i],
      [
        // Set the first result to Android and then override it with the real model
        UAParser.DEVICE.MODEL,
        UAParser.DEVICE.MODEL,
        // Hardcode the vendor to Amazon
        [UAParser.DEVICE.VENDOR, 'Amazon'],
        // Hardcode the type to tablet
        [UAParser.DEVICE.TYPE, UAParser.DEVICE.TABLET]
      ]
    ]

    const parser = new UAParser({ device: deviceRegexes })

    const getDevice = (userAgent = navigator.userAgent) => ({
      ...detect(userAgent),
      ...parser.setUA(userAgent).getResult(),
      isElectron: navigator.userAgent.toLowerCase().includes('electron')
    })

    const device = GLOBAL.device = getDevice(userAgent)

    runCompatibilityCheck(device, addAnnouncement)

    return device
  }
}

export const useDevice = () => {
  const [device, setDevice] = useState(GLOBAL.device)
  const { addAnnouncement } = useAnnouncements()

  useEffect(() => {
    setDevice(detectDevice(addAnnouncement))
  }, [addAnnouncement])
  
  return device
}

export default useDevice