import { useEffect, useState } from 'react'

let _setError

export const setGlobalError = (() => {
  let timeout

  return (error) => {
    clearTimeout(timeout)
    
    if (typeof _setError === 'function') {
      try {
        _setError(error) 
      } catch (error) {
        console.error('Setting global error failed:')
        console.error(error)
      }
    } else {
      timeout = setTimeout(() => {
        setGlobalError(error)
      }, 250)
    }
  }
})()

const useGlobalError = (initialError) => {
  const [error, setError] = useState(initialError)
  _setError = setError

  useEffect(() => {
    if (initialError && initialError !== error) {
      setError(initialError)
    }
  }, [initialError])

  return [error, setError]
}

export default useGlobalError