import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAnnouncement as addAnnouncementAction,
  removeAnnouncement as removeAnnouncementAction,
} from '~/store/actions'

export const useAnnouncements = () => {
  const dispatch = useDispatch()
  const announcements = useSelector((state) => state?.announcementsReducer?.announcements)

  const addAnnouncement = useCallback((announcement) => {
    return dispatch(addAnnouncementAction(announcement))
  }, [dispatch])

  const removeAnnouncement = useCallback((announcement) => {
    return dispatch(removeAnnouncementAction(announcement))
  }, [dispatch])

  return {
    announcements,
    addAnnouncement,
    removeAnnouncement
  }
}

export default useAnnouncements