import { SET_SOURCE } from '~/store/actions/actionTypes'

const initialState = {
  source: null,
  os: null,
  version: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_SOURCE:
    return action.payload

  default: return state
  }
}

export default reducer
