import Announcement from "~/components/common/Announcement";
import {Button, Link} from "~/components/common/Announcement/components";
import {SUPPORT_URL} from "~/config/settings";


export const FallbackError = ({ error, resetErrorBoundary }) => {
        // Call resetErrorBoundary() to reset the error boundary and retry the render.

        return (
            <Announcement subTitle={"Sorry, Something went wrong!"}>
                <Button
                    primary
                    onClick={() => resetErrorBoundary()}
                >
                    {'Retry'}
                </Button>
                <Link
                    href={SUPPORT_URL}
                >
                    {'Get help'}
                </Link>
            </Announcement>
    );
}
