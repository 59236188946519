import React, { useEffect, useRef } from 'react'
import {
  Container,
  Div,
} from '../styled/common/loading'
import LoadingAnimation from "~/components/LoadingAnimation";

const WAITED_TOO_LONG_TIMEOUT = 15000

const Loading = (props) => {
  const waitedTooLongTimeout = useRef()

  useEffect(() => {
    waitedTooLongTimeout.current = setTimeout(() => {

      if (props.shouldThrowError) {
        throw new Error('Waited too long.')
      }
    }, WAITED_TOO_LONG_TIMEOUT)

    return () => {
      clearTimeout(waitedTooLongTimeout.current)
    }
  }, [])

  return (
    <Container
      className={props.className || 'Loading'}
      css={props.customCss}
      style={props.style}
      fixed={props.fixed}
    >
      <LoadingAnimation />

      {props.message && (
        <Div>
          {props.message}
        </Div>
      )}
    </Container>
  )
}

Loading.defaultProps = {
  message: 'Please wait...',
  withLogo: true,
  shouldThrowError: false
}

export default Loading
